import Cookies from "js-cookie";

export const GDS_VERSION_COOKIE = "GDS_VERSION_cache_version_1"; // increment the cache version when you need a new GDS_VERSION to be the norm

export type GDS_VERSION = `v${number}`;

export class EngineVersionManager {
  protected defaultVersion: GDS_VERSION;
  protected domain: string;
  constructor(defaultVersion: GDS_VERSION, domain: string) {
    this.defaultVersion = defaultVersion;
    this.domain = domain;
  }

  setEngineVersion(version: GDS_VERSION) {
    console.debug(`Setting engine version: ${version}`);
    Cookies.set(GDS_VERSION_COOKIE, version, {
      expires: 365,
      domain: this.domain,
    });
  }

  getDefaultVersion(): GDS_VERSION {
    return this.defaultVersion;
  }

  getEngineVersion(): GDS_VERSION {
    const version = (Cookies.get(GDS_VERSION_COOKIE) as GDS_VERSION) || this.defaultVersion;
    console.debug(`Getting engine version: ${version}`);
    return version;
  }

  /**
   * Clears engine version cookie - engine version will default to default version
   */
  clearEngineVersion() {
    console.debug("Removed engine version cookie");
    return Cookies.remove(GDS_VERSION_COOKIE);
  }
}
