interface FormatCO2PerUnitOptions {
  includeUnit?: boolean;
  skipGramConversion?: boolean;
}

export const formatCO2PerUnit = (rawValue: number, options?: FormatCO2PerUnitOptions) => {
  const includeUnit = typeof options?.includeUnit !== "undefined" ? options?.includeUnit : true;
  const skipGramConversion = options?.skipGramConversion || false;
  let valueGrams = skipGramConversion ? rawValue : rawValue * 1000;

  if (Math.abs(valueGrams) < 1 && valueGrams !== 0) {
    return `${Math.sign(valueGrams) >= 0 ? "" : "-"}<1${includeUnit ? "g CO2e" : ""}`;
  }

  return `${valueGrams.toFixed(0)}${includeUnit ? "g CO2e" : ""}`;
};

export const formatCO2Total = (rawValueKg: number) => {
  if (rawValueKg >= 10) {
    return `${rawValueKg.toFixed(0)}kg CO2e`;
  }

  if (rawValueKg >= 1) {
    return `${rawValueKg.toFixed(1)}kg CO2e`;
  }

  return `${rawValueKg.toFixed(2)}kg CO2e`;
};
