import {
  AttributeSelection,
  AttributeSelectionsByAttributeId,
  AttributeValueType,
} from "@sourceful/shared-types";

export const attributeSelectionToApiPayload = (
  attributes: {
    [attributeId: string]: AttributeSelection;
  },
  options?: {
    excludedAttributeTypes: string[];
    excludedAttibuteIds?: string[];
  }
) => {
  let result: { [index: string]: AttributeValueType | AttributeValueType[] } = {};

  for (let attributeId in attributes) {
    const attributeSelection = attributes[attributeId];

    if (
      options?.excludedAttributeTypes.includes(attributeSelection?.attribute.type) ||
      options?.excludedAttibuteIds?.includes(attributeSelection?.attribute.id)
    ) {
      continue;
    }

    result[attributeSelection.attribute.id] = attributeSelection.value;
  }

  return result;
};

export const generateEngineAttributesPayload = (attributes: AttributeSelectionsByAttributeId) => {
  const product_attributes = attributeSelectionToApiPayload(attributes, {
    excludedAttributeTypes: ["colour"], // colour doesn't need to be passed to engine
    excludedAttibuteIds: Object.values(attributes) // filter out attributes flagged with noVariantCheck
      .filter(attributeSelection => !!attributeSelection?.attribute?.noVariantCheck)
      .map(attributeSelection => attributeSelection?.attribute?.id),
  });

  return product_attributes;
};
