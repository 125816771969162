import { ConfigurationItem, ConfigurationItemCreationAttrs } from "@sourceful/shared-types";
import { AxiosInstance } from "axios";
import { useCallback, useState } from "react";
import { applyEngineResult, EngineFetchReason, EngineFetchType } from "./applyEngineResults";
import { EngineOptions } from "./fetchEngineResponse";

export type ApplyEngineResultsToItem<T extends ConfigurationItem | ConfigurationItemCreationAttrs> =
  (args: { item: T; reason: EngineFetchReason; engineOptions?: EngineOptions }) => Promise<T>;

export interface ApplyEngineResultsToItemArgs<
  T extends ConfigurationItem | ConfigurationItemCreationAttrs
> {
  client: AxiosInstance;
  engineVersion: string | (() => string);
  item: T;
  activeAttributeId: string | null;
  engineFetchType: EngineFetchType;
  engineOptions?: Omit<EngineOptions, "one_step_change_attributes">;
  abortSignal?: AbortSignal;
  disableOneStepChangeCaching?: boolean;
}

export const CONFIGURATOR_ENGINE_UPDATE = "Configurator Engine Update";

/*
      This function is a control layer that manages engine calls in the configurator flow. 
      It centralises engine metadata updates and coordinates things like using cached results from the one_choice_options array
      and managing certain engine options e.g. the one_step_change_attributes param should always be set to the active attribute. 
  */

export const _applyEngineResultsToItem = async <
  T extends ConfigurationItem | ConfigurationItemCreationAttrs
>({
  client,
  engineVersion,
  item,
  activeAttributeId,
  engineFetchType,
  engineOptions,
  abortSignal,
  disableOneStepChangeCaching = false,
}: ApplyEngineResultsToItemArgs<T>): Promise<T> => {
  const { updatedMetadata, engineMetadata, updatedAttributes, updatedQuantity } =
    await applyEngineResult({
      client,
      configurationDetails: {
        activeAttributeIds: activeAttributeId,
        attributes: item.attributeSelection,
        metadata: item.metadata,
        currentEngineMetadata: item.engineMetadata || null,
        baseProductId: item.product.baseProductId,
      },
      engineFetchType,
      engineVersion,
      engineOptions,
      fetchOptions: {
        disableOneStepChangeCaching,
        abortSignal,
      },
    });

  const finalisedItem: T = {
    ...item,
    attributeSelection: updatedAttributes,
    quantity: updatedQuantity,
    engineMetadata: engineMetadata,
    name: engineMetadata.results?.product?.line_reference || item.name,
    metadata: updatedMetadata,
  };

  return finalisedItem;
};

export type EngineLoadingState = EngineFetchReason | "IDLE";

export const useConfiguratorEngineManager = () => {
  const [engineLoadingState, setEngineLoadingState] = useState<EngineLoadingState>("IDLE");

  const applyEngineResultsToItem = useCallback(
    async <T extends ConfigurationItem | ConfigurationItemCreationAttrs>(
      args: ApplyEngineResultsToItemArgs<T>
    ) => {
      try {
        setEngineLoadingState(args.engineFetchType.type);
        const item = await _applyEngineResultsToItem(args);
        return item;
      } catch (error) {
        throw error;
      } finally {
        setEngineLoadingState("IDLE");
      }
    },
    []
  );

  return {
    engineLoadingState,
    isFetchingEngineResponses: engineLoadingState !== "IDLE",
    setEngineLoadingState,
    applyEngineResultsToItem,
  };
};
