import { BaseProductId } from "@sourceful/shared-types";
import Dinero, { Currency } from "dinero.js";

export const doesProductUsePence = (baseProductId: BaseProductId) => {
  const usesPence = new Set<BaseProductId>([BaseProductId.STICKER, BaseProductId.RECYCLED_MAILER]);
  return usesPence.has(baseProductId);
};

export const formatUnitPrice = (
  priceInPence: number,
  currency: Currency,
  usePence: boolean = false
) => {
  if (usePence) {
    return `${priceInPence.toFixed(2)}p`;
  } else {
    return Dinero({
      amount: Math.round(priceInPence),
      currency,
    }).toFormat("$0,0.00");
  }
};

export const formatTotalPrice = (priceInPence: number, currency: Currency) => {
  return Dinero({
    amount: Math.round(priceInPence),
    currency,
  }).toFormat("$0,0.00");
};
