import { ConfigurationItem, EngineChoiceOverride } from "@sourceful/shared-types";

export * from "./payload-formatters";
export * from "./fetchEngineResponse";
export * from "./generateEngineMetadata";

export const applyEngineChoiceOverrides = (
  attributes: ConfigurationItem["attributeSelection"],
  changes: EngineChoiceOverride[]
): ConfigurationItem["attributeSelection"] => {
  if (changes.length === 0) {
    return attributes;
  }

  let newChoices: ConfigurationItem["attributeSelection"] = { ...attributes };

  changes.forEach(({ attribute, to }) => {
    const prevSelected = newChoices[attribute];

    newChoices = {
      ...newChoices,
      [attribute]: {
        attribute: prevSelected.attribute,
        value: to,
      },
    };
  });

  return newChoices;
};

export interface EngineChoiceOverridesByAttributeId {
  [attributeId: string]: EngineChoiceOverride[];
}

export const sortEngineOverridesByAttributeId = (
  changes: EngineChoiceOverride[]
): EngineChoiceOverridesByAttributeId => {
  return changes.reduce((result, change) => {
    if (!result[change.attribute]) {
      result[change.attribute] = [];
    }
    result[change.attribute].push(change);
    return result;
  }, {} as EngineChoiceOverridesByAttributeId);
};
