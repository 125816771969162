import {
  EngineMetadataSummary,
  TransportPriority,
  SplitDeliveryQuantities,
} from "@sourceful/shared-types";
import isAfter from "date-fns/isAfter";

export const isQuoteExpired = (expiryDate: Date) => {
  return isAfter(new Date(), expiryDate);
};

export interface IsEngineQuoteValidArgs {
  engineMetadata: EngineMetadataSummary | null;
  currentConfigDetails: {
    postcode: string;
    attributesHash: string;
    maxLeadTime: number | null;
    transportPriority: TransportPriority | undefined;
    splitDeliveryQuantities: SplitDeliveryQuantities | null;
  };
  onValidation?: (info: { isMaxLeadTimeInvalid: boolean; isValid: boolean }) => void;
}

export const isEngineQuoteValid = ({
  engineMetadata,
  currentConfigDetails: {
    postcode,
    attributesHash,
    maxLeadTime,
    transportPriority,
    splitDeliveryQuantities,
  },
  onValidation,
}: IsEngineQuoteValidArgs) => {
  const isMaxLeadTimeInvalid = Boolean(
    (maxLeadTime && !engineMetadata?.maxLeadTime) ||
      (engineMetadata?.maxLeadTime && maxLeadTime && engineMetadata.maxLeadTime > maxLeadTime)
  );

  const isValid = Boolean(
    engineMetadata &&
      attributesHash === engineMetadata.attributesHash && // check the attributes haven't changed since the quote was issued
      engineMetadata.quote_expiry &&
      !isQuoteExpired(new Date(engineMetadata.quote_expiry)) && // check expiry date
      engineMetadata.product.postcode === postcode && // check postcode hasn't changed
      engineMetadata.transportPriority === transportPriority && // check priority hasn't changed
      !isMaxLeadTimeInvalid &&
      engineMetadata?.splitDeliveryQuantities?.fast === splitDeliveryQuantities?.fast &&
      engineMetadata?.splitDeliveryQuantities?.slow === splitDeliveryQuantities?.slow
  );

  onValidation && onValidation({ isMaxLeadTimeInvalid, isValid });

  return isValid;
};
