import { Currency, BasketTotals, DiscountData } from "@sourceful/shared-types";
import Dinero from "dinero.js";
import { CO2_PRICE_PER_KG_PENCE } from "./constants/co2PricePerKg";
import { round } from "./utils/round";

export interface BasketItemPrice {
  total_price_pence: number;
  total_co2e: number;
  production_cost_pence: number;
  delivery_total_pence: number;
}

// shared with sourceful-frontend TODO: move to shared-utils
export interface GetBasketTotalsArgs {
  currency: Currency;
  shouldOffsetCarbon: boolean;
  basketItems: BasketItemPrice[];
  CO2PricePerKgPence?: number;
  customHandlingCost?: number;
  discount?: DiscountData;
}

export interface Price {
  raw: number;
  formatted: string;
}

// This should just format the Engine data and return a nice ViewModel for the UI, no calculations!
// Maybe CO2 is the exception...
export const getBasketTotals = ({
  currency,
  shouldOffsetCarbon,
  basketItems,
  CO2PricePerKgPence = CO2_PRICE_PER_KG_PENCE, // Price per KG
  customHandlingCost,
  discount,
}: GetBasketTotalsArgs): BasketTotals => {
  const totalCO2 = calcTotalCO2e(basketItems);

  // engine sends these totals to the whole pence - they should always add up to total_price_pence
  const itemsTotal = getBasketItemTotals(basketItems);
  const itemsDeliveryTotal = getBasketItemsDeliveryTotal(basketItems);

  const carbonOffsetTotal = totalCO2 * CO2PricePerKgPence;

  const subtotal =
    itemsTotal +
    itemsDeliveryTotal +
    (shouldOffsetCarbon ? carbonOffsetTotal : 0) +
    (customHandlingCost ? customHandlingCost : 0);

  // TO match xero and stripe we will use the line total (production + delivery cost) to the nearest pence (2dp to the pound) - engine does this for us
  // We sum the line totals up, calc vat, then round the result to the nearest pence
  const lineTotals = basketItems.map(item => {
    return item.total_price_pence;
  });

  const itemsAndDeliveryTotal = lineTotals.reduce((total, itemTotal) => {
    total += itemTotal;
    return total;
  }, 0);

  const calcVAT = (n: number) => {
    const VAT_RATE = 0.2;
    return Number((n * VAT_RATE).toFixed(0));
  };

  let VAT: number;
  let finalTotal: number;

  if (discount) {
    // This has come from Stripe just use it
    VAT = discount.tax;
    // Just final total to include discount and new VAT
    finalTotal = Math.round(subtotal - discount.amount + VAT);
  } else {
    VAT = calcVAT(itemsAndDeliveryTotal + (customHandlingCost ?? 0));

    finalTotal = Math.round(subtotal + VAT);
  }

  const totals = {
    itemsTotal: { raw: itemsTotal, formatted: formatPrice(itemsTotal, currency) },
    deliveryTotal: {
      raw: itemsDeliveryTotal,
      formatted: itemsDeliveryTotal > 0 ? formatPrice(itemsDeliveryTotal, currency) : "Free",
    },
    itemsAndDeliveryTotal: {
      raw: itemsAndDeliveryTotal,
      formatted: formatPrice(itemsAndDeliveryTotal, currency),
    },
    subtotal: { raw: subtotal, formatted: formatPrice(subtotal, currency) }, // format price rounds the raw total
    finalTotal: {
      raw: finalTotal,
      formatted: formatPrice(finalTotal, currency),
    },
    carbonOffsetTotal: {
      raw: carbonOffsetTotal,
      formatted: formatPrice(carbonOffsetTotal, currency),
    },
    vatTotal: { raw: VAT, formatted: formatPrice(VAT, currency) },
    totalCO2, // this is rounded to the correct dp
    CO2PricePerKgPence,
  };

  if (customHandlingCost) {
    return {
      ...totals,
      customHandlingTotal: {
        raw: customHandlingCost,
        formatted: formatPrice(customHandlingCost, currency),
      },
    };
  }
  return totals;
};

export const roundTotalCO2e = (rawValueKg: number) => {
  if (rawValueKg >= 10) {
    return round(rawValueKg);
  }

  if (rawValueKg >= 1) {
    return round(rawValueKg, 1);
  }

  return round(rawValueKg, 2);
};

export const calcTotalCO2e = (basketItems: BasketItemPrice[]) => {
  let total = 0;
  for (let item of basketItems) {
    const score = item.total_co2e;
    if (score) {
      total += score;
    }
  }

  // apply rounding rules to c02 and then use that to calc carbon offset total - this becomes source of truth
  return roundTotalCO2e(total);
};

export const getBasketItemTotals = (basketItems: BasketItemPrice[]) => {
  let total = 0;

  for (let item of basketItems) {
    const price = item.production_cost_pence;

    if (price) {
      total += price;
    }
  }

  return total;
};

export const getBasketItemsDeliveryTotal = (basketItems: BasketItemPrice[]) => {
  let total = 0;

  for (let item of basketItems) {
    const deliveryTotalPence = item.delivery_total_pence;

    if (deliveryTotalPence) {
      total += deliveryTotalPence;
    }
  }

  return total;
};

export const formatPrice = (priceInPence: number, currency: Currency) => {
  if (priceInPence < 5) {
    return `${round(priceInPence)}p`;
  } else {
    return Dinero({
      amount: Math.round(priceInPence),
      currency,
    }).toFormat("$0,0.00");
  }
};
