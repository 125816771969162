import { AttributeSelectionsByAttributeId } from "@sourceful/shared-types";
import { AxiosInstance } from "axios";
import { EngineOptions, fetchEngineResponse } from "./fetchEngineResponse";
import { generateEngineAttributesPayload } from "./payload-formatters";

export interface GenerateEngineMetadataArgs {
  client: AxiosInstance;
  attributes: AttributeSelectionsByAttributeId;
  baseProductId: number;
  options?: EngineOptions;
  abortSignal?: AbortSignal;
  engineVersion: string | (() => string);
}

// Formats a config items attributes  and calls fetcher
export const generateEngineMetadata = async ({
  client,
  attributes,
  baseProductId,
  options,
  abortSignal,
  engineVersion,
}: GenerateEngineMetadataArgs) => {
  const product_attributes = generateEngineAttributesPayload(attributes);

  const res = await fetchEngineResponse({
    client,
    params: {
      product_attributes,
      base_product_id: baseProductId,
      postcode: options?.postcode,
      write_to_db: options?.write_to_db || false, // default to false
      split_delivery_quantities: options?.split_delivery_quantities,
      priority: options?.transportPriority,
      one_step_change_attributes: options?.one_step_change_attributes || undefined,
    },
    abortSignal,
    engineVersion,
  });

  if (res?.request?.fromCache === true) {
    console.log(`Serving cached engine response`);
  }

  return res?.data;
};
