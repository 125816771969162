import { AttributeValueType } from "@sourceful/shared-types";
import { sortAlphabetically } from "../misc/sort";

export const createStringHashFromAttributePayload = (attributesPayload: {
  [attributeName: string]: AttributeValueType | AttributeValueType[];
}) => {
  return Object.keys(attributesPayload)
    .sort(sortAlphabetically)
    .reduce((acc: string[], attrId: string) => {
      const value = attributesPayload[attrId];

      if (Array.isArray(value)) {
        return acc;
      }

      const valueId = value.id;

      if (!valueId) {
        return acc;
      }

      const keyValuePair = `${attrId}:${valueId}`;

      acc.push(keyValuePair);
      return acc;
    }, [] as string[])
    .join(";");
};
