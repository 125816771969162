import { formatCO2PerUnit, formatTotalPrice, formatUnitPrice } from "../number-formatters";
import { ProductAttributeValue, EngineResult, SpeedResult } from "@sourceful/shared-types";
import { Currency } from "dinero.js";

const DAYS_IN_WEEK = 7;

export const daysToWeeks = (days: number) => {
  return Math.round(days / DAYS_IN_WEEK);
};

export type CalculatedLcaDeltas = {
  priceDelta: number | null;
  newSpeed: SpeedResult | null;
  lcaDelta: number | null;
  speedDelta: string | null;
};

export const formatPriceDelta = (delta: number, currency: Currency, usePence?: boolean) => {
  const sign = Math.sign(delta);

  if (delta === 0) {
    return "";
  }

  const value = formatUnitPrice(delta, currency, usePence);

  if (sign === 1) {
    return `+${value}`;
  }
  return `${value}`;
};

export const getQuantityPriceMessage = (original: number, delta: number): string => {
  const percentage = Math.round((delta / original) * 100);
  const sign = Math.sign(delta);

  if (sign >= 0 || Math.abs(percentage) === 0) {
    // don't show message if option is more expensive
    return "";
  }

  return `Save ${Math.abs(percentage).toFixed(0)}%`;
};

export const formatEnginePercentage = (original: number, delta: number) => {
  if (original <= 0 || delta === 0) {
    return "";
  }

  const sign = Math.sign(delta);
  const percentage = delta / original;

  if (Math.abs(percentage) < 0.01) {
    return `${sign >= 0 ? "+" : "-"}<1%`;
  }

  return `${sign >= 0 ? "+" : ""}${(100 * percentage).toLocaleString("en-GB", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })}%`;
};

export const isEngineResultEmpty = (result: EngineResult): boolean => {
  return !result.lca || result.price === null || !result.price_amount || !result.speed;
};

export const calculateEngineDeltas = (
  currentResult: EngineResult,
  choiceResult: EngineResult
): CalculatedLcaDeltas => {
  if (isEngineResultEmpty(currentResult) || isEngineResultEmpty(choiceResult)) {
    return { priceDelta: null, newSpeed: null, lcaDelta: null, speedDelta: null };
  }

  let choiceResultPrice = choiceResult.price_amount!.price_per_unit_pence;

  let currentPrice = currentResult.price_amount!.price_per_unit_pence;
  let currentLca = currentResult.lca!.global_warming.summary.impact_per_piece;

  let priceDelta = Math.round(choiceResultPrice) - Math.round(currentPrice);

  let newSpeed = choiceResult.speed;
  let lcaDelta = choiceResult.lca!.global_warming.summary.impact_per_piece - currentLca;

  let speedDelta = "";
  let speedDeltaRaw = daysToWeeks(choiceResult.speed!.max) - daysToWeeks(currentResult.speed!.max);

  let speedDeltaWeekString = `week${speedDeltaRaw !== 1 ? "s" : ""}`;

  if (Math.sign(speedDeltaRaw) === -1) {
    // decreasing

    speedDelta = `${speedDeltaRaw} ${speedDeltaWeekString}`;
  } else if (Math.sign(speedDeltaRaw) === 1) {
    // increasing

    speedDelta = `+${speedDeltaRaw} ${speedDeltaWeekString}`;
  }

  return { priceDelta, newSpeed, lcaDelta, speedDelta };
};

export type CalculatedDeliveryLcaDeltas = {
  priceDelta: number | null;
  newSpeed: SpeedResult | null;
};

export const getOptionEngineData = (
  option: ProductAttributeValue,
  attributeEngineData: { [attributeValueOrId: string]: EngineResult }
) => {
  if (typeof option.value === "string" || typeof option.value === "number") {
    return attributeEngineData[option.value];
  }

  return attributeEngineData[option.value.id];
};

export type OneStepChangeValue = {
  value?: string;
  change?: string;
  isIncreasing?: boolean;
};

export type OneStepChangePackaging = {
  packSize: number | undefined;
  numPallets: number | undefined;
  numCartons: number | undefined;
};

export type OneStepChangeAmount = {
  quantity: number | undefined;
};

export type OneStepChangeDeliveryValue = {
  value?: string;
};

export type OneStepChangeSplitSpeed = {
  min: number | undefined;
  max: number | undefined;
  text: string | undefined;
  transportType: string | undefined;
  quantity: number | undefined;
};

export type DeliveryOptionEngineMetaDataValue = {
  value?: string;
  min?: number;
  max?: number;
};

export type OneStepChangeMetadata = {
  price: OneStepChangeValue;
  speed?: OneStepChangeValue;
  lca: OneStepChangeValue;
  packaging?: OneStepChangePackaging;
  amount?: OneStepChangeAmount;
  fastSplit?: OneStepChangeSplitSpeed;
  slowSplit?: OneStepChangeSplitSpeed;
  deliveryPrice?: OneStepChangeDeliveryValue;
  split?: DeliveryOptionEngineMetaDataValue;
};

interface GetOneStepChangeMetadataProps {
  option: EngineResult;
  selectedOption: EngineResult;
  currency: Currency;
  showSpeed?: boolean;
  isQuantityStep?: boolean;
}

export const getOneStepChangeMetadata = ({
  option,
  selectedOption,
  currency = "GBP",
  showSpeed = true,
  isQuantityStep = false,
}: GetOneStepChangeMetadataProps): OneStepChangeMetadata => {
  const { priceDelta, lcaDelta, speedDelta, newSpeed } = calculateEngineDeltas(
    selectedOption,
    option
  );

  let priceDiffPercentage: number | undefined = undefined;

  if (isQuantityStep && selectedOption.price && option.price) {
    const priceDifference = 1 - option.price / selectedOption.price;
    priceDiffPercentage = 100 * priceDifference;
  }

  const optionPriceFormatted = formatTotalPrice(
    option.price_amount!.price_per_unit_pence,
    currency
  );
  const priceDeltaFormatted = formatPriceDelta(priceDelta!, currency);

  const isSplitDelivery = option.split_delivery;

  const quantityPriceProps = {
    value: optionPriceFormatted,
    change: priceDiffPercentage ? `${Math.round(priceDiffPercentage)}` : undefined,
    isIncreasing: false,
  };

  const priceProps = {
    value: optionPriceFormatted,
    change: priceDeltaFormatted,
    isIncreasing: priceDelta ? priceDelta > 0 : undefined,
  };

  const deliveryOptionPriceFormatted = formatTotalPrice(
    option.price_amount!.price_breakdown.transport_cost_pence,
    currency
  );

  const optionValueEngineMetaData: OneStepChangeMetadata = {
    deliveryPrice: {
      value: deliveryOptionPriceFormatted,
    },
    price: isQuantityStep ? quantityPriceProps : priceProps,
    speed: showSpeed
      ? {
          value: newSpeed?.human_readable,
          change: speedDelta ? speedDelta : undefined,
          isIncreasing: newSpeed!.min > selectedOption.speed!.min,
        }
      : undefined,
    lca: {
      value: option?.lca?.global_warming?.summary.impact_per_piece
        ? `${formatCO2PerUnit(option.lca.global_warming.summary.impact_per_piece)}`
        : "",
      change:
        selectedOption?.lca?.global_warming.summary.impact_per_piece && lcaDelta
          ? `${formatEnginePercentage(
              selectedOption.lca.global_warming.summary.impact_per_piece,
              lcaDelta
            )}`
          : "",
      isIncreasing: lcaDelta ? lcaDelta > 0 : undefined,
    },
    packaging: {
      packSize: option.pack_size,
      numPallets: option.secondary_packaging?.pallet_info?.number_of_pallets,
      numCartons: option.secondary_packaging?.carton_info?.number_of_cartons,
    },
    amount: {
      quantity: option.amount?.pieces.quantity,
    },
    fastSplit: isSplitDelivery
      ? {
          min: option.speed?.fast?.min,
          max: option.speed?.fast?.max,
          text: option.speed?.fast?.human_readable,
          transportType: option.speed?.fast?.transport_type,
          quantity: option.speed?.fast?.quantity,
        }
      : undefined,
    slowSplit: isSplitDelivery
      ? {
          min: option.speed?.slow?.min,
          max: option.speed?.slow?.max,
          text: option.speed?.slow?.human_readable,
          transportType: option.speed?.slow?.transport_type,
          quantity: option.speed?.slow?.quantity,
        }
      : undefined,
    split: isSplitDelivery
      ? undefined
      : { min: option.speed?.min, max: option.speed?.max, value: option.speed?.human_readable },
  };

  return optionValueEngineMetaData;
};

export const formatSelectionOptionEngineMetadata = (
  selectedOption: EngineResult,
  currency: Currency = "GBP"
): OneStepChangeMetadata => {
  return {
    price: {
      value: selectedOption.price_amount
        ? formatTotalPrice(selectedOption.price_amount.price_per_unit_pence, currency)
        : undefined,
    },
    lca: {
      value: selectedOption?.lca?.global_warming?.summary.impact_per_piece
        ? `${formatCO2PerUnit(selectedOption.lca.global_warming.summary.impact_per_piece)}`
        : undefined,
    },
  };
};

export const formatEngineMetadataIfSelectedCustomValue = (
  selectedValueId: string,
  option: ProductAttributeValue,
  engineResult: EngineResult | null
) => {
  if (!engineResult) {
    return undefined;
  }

  const isSelected = option.value.id === selectedValueId;

  if (!isSelected) {
    return undefined;
  }

  return formatSelectionOptionEngineMetadata(engineResult);
};
