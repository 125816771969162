import {
  BaseProductId,
  ConfigurationItem,
  EngineResponse,
  EngineResult,
  ProductAttributeType,
  TransportPriority,
} from "@sourceful/shared-types";
import { AxiosInstance } from "axios";
import isArray from "lodash/isArray";
import { getQuantity } from "../getQuantity";
import { applyEngineChoiceOverrides } from "./engine-overrides";
import { EngineOptions } from "./fetchEngineResponse";
import { generateEngineMetadata } from "./generateEngineMetadata";

export enum EngineFetchReason {
  ATTRIBUTE_VALUE_CHANGE = "ATTRIBUTE_VALUE_CHANGE",
  POSTCODE_CHANGED = "POSTCODE_CHANGED",
  ONE_STEP_CHANGE_ATTRIBUTES_CHANGED = "ONE_STEP_CHANGE_ATTRIBUTES_CHANGED",
  CREATE_CONFIG_ITEM = "CREATE_CONFIG_ITEM",
  PRESET_SELECTED = "PRESET_SELECTED",
  OPT_IN_TO_LIVE_PRICING = "OPT_IN_TO_LIVE_PRICING",
  TRANSPORT_PRIORITY_CHANGE = "TRANSPORT_PRIORITY_CHANGE",
  TRANSPORT_DIALOG_OPENED = "TRANSPORT_DIALOG_OPENED",
  INITIAL_FETCH = "INITIAL_FETCH",
}

export const engineFetchReasons = Object.values(EngineFetchReason);

export type EngineFetchType =
  | {
      type: EngineFetchReason.ATTRIBUTE_VALUE_CHANGE;
      payload: { attributeId: string; attributeValueId: string; type: ProductAttributeType };
    }
  | { type: EngineFetchReason.POSTCODE_CHANGED }
  | { type: EngineFetchReason.ONE_STEP_CHANGE_ATTRIBUTES_CHANGED }
  | { type: EngineFetchReason.INITIAL_FETCH }
  | { type: EngineFetchReason.CREATE_CONFIG_ITEM }
  | { type: EngineFetchReason.PRESET_SELECTED }
  | { type: EngineFetchReason.OPT_IN_TO_LIVE_PRICING }
  | {
      type: EngineFetchReason.TRANSPORT_PRIORITY_CHANGE;
      payload: {
        priority: TransportPriority;
        splitDeliveryQuantities?: { fast: number; slow: number };
      };
    }
  | { type: EngineFetchReason.TRANSPORT_DIALOG_OPENED };

/*  
    A class that tracks the attribute values that have changed in the configurator and whether the engine should be refreshed.
*/

class AttributeChangesHandler {
  attributeChanges: string[] = [];
  setAttributeChanges = (attributeChanges: string[]) => {
    this.attributeChanges = attributeChanges;
  };

  clearAttributeChangeHistory = () => {
    this.setAttributeChanges([]);
  };

  recordAttributeChange = (attributeId: string) => {
    const lastChangedAttribute = this.attributeChanges[this.attributeChanges.length - 1];

    if (lastChangedAttribute === attributeId) {
      return;
    }

    if (!lastChangedAttribute) {
      return this.setAttributeChanges([attributeId]);
    }

    this.setAttributeChanges([lastChangedAttribute, attributeId]);
  };
  shouldRefreshEngineMetadata = () => {
    return this.attributeChanges.length > 1;
  };
}

export const attributeChangesHandler = new AttributeChangesHandler();

export interface ApplyEngineResultArgs {
  client: AxiosInstance;
  engineFetchType: EngineFetchType;
  configurationDetails: {
    baseProductId: BaseProductId;
    attributes: ConfigurationItem["attributeSelection"];
    metadata: ConfigurationItem["metadata"];
    activeAttributeIds: string | string[] | null;
    currentEngineMetadata: EngineResponse | null;
  };
  engineVersion: string | (() => string);
  engineOptions?: Omit<EngineOptions, "one_step_change_attributes">;

  fetchOptions?: {
    abortSignal?: AbortSignal;
    disableOneStepChangeCaching?: boolean;
  };
}

export const applyEngineResult = async ({
  client,
  configurationDetails,
  engineVersion,
  fetchOptions,
  engineOptions,
  engineFetchType,
}: ApplyEngineResultArgs) => {
  const { metadata, currentEngineMetadata, attributes, baseProductId, activeAttributeIds } =
    configurationDetails;

  const disableOneStepChangeCaching = fetchOptions?.disableOneStepChangeCaching ?? true;
  let transportPriority = engineOptions?.transportPriority || metadata?.transportPriority;
  let splitDeliveryQuantities =
    engineOptions?.split_delivery_quantities || metadata?.splitDeliveryQuantities;

  // the prefetched engine result in the one step changes is only valid if only the current attribute has changed since the last engine call
  let prefetchedEngineResult: EngineResult | undefined;

  if (
    engineFetchType.type === "ATTRIBUTE_VALUE_CHANGE" &&
    !(transportPriority === TransportPriority.SPLIT && engineFetchType.payload.type === "quantity") // if the transport priority is split and quantity changes we need to refresh the engine metadata to update the fast/slow amounts in the transport options field
  ) {
    attributeChangesHandler.recordAttributeChange(engineFetchType.payload.attributeId);

    const oneStepChangesForAttribute =
      currentEngineMetadata?.one_choice_options[engineFetchType.payload.attributeId];

    const oneStepchangeResult =
      oneStepChangesForAttribute?.[engineFetchType.payload.attributeValueId];

    if (oneStepchangeResult) {
      prefetchedEngineResult = oneStepchangeResult;
    }
  } else {
    attributeChangesHandler.clearAttributeChangeHistory();
  }

  let engineMetadata: EngineResponse;

  if (
    currentEngineMetadata &&
    prefetchedEngineResult &&
    !attributeChangesHandler.shouldRefreshEngineMetadata() &&
    engineFetchType.type === "ATTRIBUTE_VALUE_CHANGE" &&
    !disableOneStepChangeCaching
  ) {
    console.log("applyEngineResultsToItem - Using prefetched engine result");
    engineMetadata = {
      ...currentEngineMetadata,
      results: prefetchedEngineResult,
    };
  } else {
    console.log("applyEngineResultsToItem - Refreshing engine metadata", engineFetchType.type);
    engineMetadata = await generateEngineMetadata({
      client,
      attributes,
      baseProductId,
      options: {
        transportPriority: transportPriority,
        split_delivery_quantities: metadata?.splitDeliveryQuantities,
        ...engineOptions,
        one_step_change_attributes: isArray(activeAttributeIds)
          ? activeAttributeIds
          : activeAttributeIds
          ? [activeAttributeIds]
          : [],
      },
      abortSignal: fetchOptions?.abortSignal,
      engineVersion,
    });
  }

  // Check if the pack size is greater than the quantity, if so, undo split delivery
  const packSize = engineMetadata?.results?.pack_size;
  let quantity = getQuantity(attributes);

  if (transportPriority === TransportPriority.SPLIT) {
    let shouldResetTransportPriority = false;
    if (!packSize || packSize >= quantity) {
      console.log("applyEngineResultsToItem - Pack size is invalid, resetting transport priority");
      transportPriority = TransportPriority.PRICE;
      splitDeliveryQuantities = undefined;
      shouldResetTransportPriority = true;
    } else if (
      (splitDeliveryQuantities?.slow && splitDeliveryQuantities.slow >= quantity) ||
      (splitDeliveryQuantities?.fast && splitDeliveryQuantities?.fast >= quantity)
    ) {
      console.log(
        "applyEngineResultsToItem - Split delivery quantities are invalid, resetting quantities according to pack size:",
        packSize
      );
      splitDeliveryQuantities = {
        fast: packSize,
        slow: quantity - packSize,
      };

      shouldResetTransportPriority = true;
    } else if (
      splitDeliveryQuantities?.fast &&
      splitDeliveryQuantities.fast < quantity &&
      engineFetchType.type === EngineFetchReason.ATTRIBUTE_VALUE_CHANGE &&
      engineFetchType.payload.type === "quantity"
    ) {
      console.log(
        "applyEngineResultsToItem - Refreshing delivery quantities due to quantity change",
        packSize
      );

      splitDeliveryQuantities = {
        fast: splitDeliveryQuantities?.fast,
        slow: quantity - splitDeliveryQuantities?.fast,
      };

      shouldResetTransportPriority = true;
    }

    if (shouldResetTransportPriority) {
      engineMetadata = await generateEngineMetadata({
        client,
        attributes,
        baseProductId,
        options: {
          ...engineOptions,
          transportPriority: transportPriority,
          split_delivery_quantities: splitDeliveryQuantities,
          one_step_change_attributes: isArray(activeAttributeIds)
            ? activeAttributeIds
            : activeAttributeIds
            ? [activeAttributeIds]
            : [],
        },
        engineVersion,
        abortSignal: fetchOptions?.abortSignal,
      });
    }
  }

  const rawEngineOverrides =
    engineMetadata?.results?.validation_object?.attribute_changes ||
    engineMetadata?.validation_object?.attribute_changes || // accounts for rfq values where results object is missing
    [];

  let updatedAttributes = attributes;
  let updatedQuantity = quantity;

  if (rawEngineOverrides.length > 0) {
    updatedAttributes = applyEngineChoiceOverrides(attributes, rawEngineOverrides);

    // qty column must be kept in sync with the value in the attributes selection
    updatedQuantity = getQuantity(updatedAttributes);
  }

  const updatedMetadata = {
    ...metadata,
    splitDeliveryQuantities: splitDeliveryQuantities,
    transportPriority,
  };

  return {
    updatedAttributes,
    updatedQuantity,
    updatedMetadata,
    engineMetadata,
  };
};
