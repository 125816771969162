import { AttributeSelectionsByAttributeId, BasicValue } from "@sourceful/shared-types";

export const getQuantitySelection = (attributes: AttributeSelectionsByAttributeId) => {
  return Object.values(attributes).find(selection => selection.attribute.type === "quantity");
};

export const getQuantity = (attributes: AttributeSelectionsByAttributeId) => {
  const qtySelection = getQuantitySelection(attributes)?.value;

  if (Array.isArray(qtySelection)) {
    throw new Error("Quantity value should not be stored as array");
  }

  if (!qtySelection) {
    throw new Error("No Quantity Selected");
  }

  return Number((qtySelection as BasicValue).value);
};
