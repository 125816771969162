import { EngineResult } from "@sourceful/shared-types";
import { Currency } from "dinero.js";
import { formatUnitPrice } from "./formatPrice";

export const calculateProductionUnitCost = (
  engineResults: EngineResult,
  currency: Currency,
  shouldUsePenceForUnitPrice: boolean
) => {
  let productionCostPence = engineResults?.price_amount?.production_unit_cost_pence;

  // old quotes don't have production_unit_cost_pence so we need to derive it from the total unit price - delivery cost per unit
  if (
    productionCostPence === undefined &&
    engineResults?.price_amount?.price_per_unit_pence &&
    engineResults.amount?.pieces.quantity
  ) {
    const pricePerUnitPence = engineResults?.price_amount?.price_per_unit_pence;
    const quantity = engineResults.amount?.pieces.quantity;
    const totalTransportCostPence = engineResults.price_amount.price_breakdown.transport_cost_pence;

    const unitTransportCostPence = totalTransportCostPence ? totalTransportCostPence / quantity : 0;
    productionCostPence = pricePerUnitPence - unitTransportCostPence;
  }

  if (productionCostPence === undefined) {
    console.error("Failed to calculate production unit price", engineResults);
  }

  return formatUnitPrice(productionCostPence || 0, currency, shouldUsePenceForUnitPrice);
};
