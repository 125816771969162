import { EngineResponse, TransportPriority, EngineParams } from "@sourceful/shared-types";
import { AxiosInstance, AxiosResponse } from "axios";

export interface EngineOptions {
  postcode?: string;
  write_to_db?: boolean;
  transportPriority?: TransportPriority;
  split_delivery_quantities:
    | {
        fast: number;
        slow: number;
      }
    | undefined;
  one_step_change_attributes?: string[];
}

export interface FetchEngineResponseArgs {
  client: AxiosInstance;
  params: EngineParams;
  abortSignal?: AbortSignal;
  engineVersion: string | (() => string);
}

export const fetchEngineResponse = async ({
  client,
  params,
  abortSignal,
  engineVersion,
}: FetchEngineResponseArgs): Promise<AxiosResponse<EngineResponse>> => {
  return client.post("/engine", params, {
    signal: abortSignal,
    headers: {
      gds_version: typeof engineVersion === "string" ? engineVersion : engineVersion(),
      countrywide_delivery: true,
      free_delivery: true,
    },
  });
};
