import Cookies from "js-cookie";
import { FinalMileMeta } from "@sourceful/shared-types";

const FINAL_MILE_KEY = "SFE_FINAL_MILE";
export const DEFAULT_POSTCODE = "E4 6AN";
const defaultRetVal: FinalMileMeta = { postcode: DEFAULT_POSTCODE, countryCode: "GB" };

export class FinalMileManager {
  protected domain: string;

  constructor(domain: string) {
    this.domain = domain;
  }

  saveFinalMileMeta(meta: FinalMileMeta) {
    Cookies.set(FINAL_MILE_KEY, JSON.stringify(meta), {
      expires: 365,
    });
  }

  getFinalMileMeta(): FinalMileMeta {
    const meta = Cookies.get(FINAL_MILE_KEY);
    if (!meta) {
      return defaultRetVal;
    }

    return JSON.parse(meta);
  }
}
