import {
  EngineMetadataSummary,
  EngineResponse,
  SplitDeliveryQuantities,
  TransportPriority,
} from "@sourceful/shared-types";

// Converts an engine response to the summary object saved against a basket item
export const engineResponseToMetadataSummary = (
  { results, quote_expiry, quote_reference, transport_options }: EngineResponse,
  attributesHash: string,
  maxLeadTime: number | null,
  transportPriority: TransportPriority | undefined,
  splitDeliveryQuantities: SplitDeliveryQuantities | undefined
): EngineMetadataSummary => {
  if (!results || !results.product?.postcode || !results?.speed?.max) {
    throw new Error("Could not format engine response");
  }

  return {
    results,
    quote_expiry: quote_expiry || "",
    quote_reference: quote_reference || "",
    product: {
      postcode: results.product.postcode,
      product_type: results.product.product_type,
      line_reference: results.product.line_reference,
    },
    transportPriority,
    transportOptions: transport_options,
    attributesHash,
    maxLeadTime, // the desired max lead time set in the configurator transport selection step
    splitDeliveryQuantities,
  };
};
