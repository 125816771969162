/**
 * A comparator function for sorting string-arrays alphabetically (as opposed to the default Array.prototype.sort implementation that sorts lexicographically without accounting for letters having different cases).
 *
 * @example `["f", "a", "e", "Adam", "john", "doe"].sort(sortAlphabetically)` produces `["a", "Adam", "doe", "e", "f", "john"]`
 *
 * @param a string
 * @param b string
 * @param order `asc` | `desc` Whether to sort in ascending order or not. Defaults to `asc`.
 * @returns number
 */
export function sortAlphabetically(a: string, b: string, order?: "asc" | "desc") {
  a = a.toLowerCase();
  b = b.toLowerCase();
  order ??= "asc";

  function sortAsc() {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  const sortAscResult = sortAsc();
  if (order === "desc" && sortAscResult !== 0) return -sortAscResult;
  return sortAscResult;
}
